import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class FrmPredefinidoService {
  async getFrmPredefinidoAll() {
    const frmpredefinidos = await fetchWrapper.get(`${ruta}/frmpredefinidos`);
    return frmpredefinidos;
  }

  async sendFrmNew(datos) {
    const nuevomedico = await fetchWrapper.post(
      `${ruta}/frmpredefinidos`,
      datos
    );
    return nuevomedico;
  }

  async updateFrm(datos) {
    const medicoActualizado = await fetchWrapper.put(
      `${ruta}/frmpredefinidos/` + datos.id,
      datos
    );
    return medicoActualizado;
  }

  async deleteFrm(id) {
    return await fetchWrapper.delete(`${ruta}/frmpredefinidos/${id}`);
  }
}
